<template>
  <div
    :class="['nanny-list-item', { 'nanny-list-item--archived': isArchived }]"
  >
    <BaseTag
      v-if="isArchived"
      :text="'Ogłoszenie archiwalne'"
      class="nanny-list-item__tag"
    />

    <BaseH2 :text="readyTitle" class="nanny-list-item__title" />

    <div class="container-photo-empl nanny-list-item__container-photo-empl">
      <BlockPhotoMain
        :src="item.details.photoMain?.src"
        :default-color-num="item.details.photoMainColorNum"
        :is-list-view="true"
        class="photo container-photo-empl__photo"
      />
      <div class="employment-block container-photo-empl__employment-block">
        <BaseH3
          :text="item.employment.type.label"
          class="type employment-block__type"
        />
        <BaseText
          :text="readyEmploymentDetails"
          class="details employment-block__details"
        />
        <BaseGroupChipItem
          :label="readyFeeHour"
          :style="'background-color: #BFB3F9;'"
          class="fee-chip employment-block__fee-chip"
        />
      </div>
    </div>

    <div class="kids-block nanny-list-item__kids-block">
      <BaseGroupIconLabel
        :items="item.details.kids"
        class="kids kids-block__kids"
      />

      <base-text
        v-if="item.details.isSpecialCare"
        :is-small="true"
        class="years kids-block__special-care"
        ><span>!</span> {{ item.details.specialCare.labelShort }}</base-text
      >
    </div>

    <div class="perks-container nanny-list-item__perks-container">
      <BaseGroupChipItem v-if="driverLicense" :label="driverLicense.label" />

      <div class="languages-block perks-container__languages-block">
        <img
          v-for="language in item.requirements.languages"
          :key="language.label"
          :src="language.iconSrc"
          class="icon languages-block__icon"
        />
      </div>
    </div>

    <div
      class="indicators nanny-list-item__indicators"
      :indicators-amount="indicatorsAmount"
    >
      <div v-if="isGeo" class="geo indicators__geo">
        <SvgIconsLocation24 class="icon geo__icon" />
        <label class="label geo__label">
          {{ item.details.location.label }} od Ciebie
        </label>
      </div>

      <div v-if="isDateAdded" class="date-added indicators__date-added">
        <SvgIconsCalendar class="icon date-added__icon" />
        <label class="label date-added__label">
          {{ dateAddedString }}
        </label>
      </div>

      <div v-if="isViewsAmount" class="views-amount indicators__views-amount">
        <SvgIconsViewsAmount class="icon views-amount__icon" />
        <label class="label views-amount__label">
          {{ viewsAmountString }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/reactivity";

import BaseH2 from "@/components/UI/Base/BaseH2.vue";
import BaseText from "@/components/UI/Base/BaseText.vue";
import BlockPhotoMain from "@/components/BlockPhotoMain.vue";
import BaseH3 from "@/components/UI/Base/BaseH3.vue";
import BaseGroupChipItem from "@/components/UI/Base/BaseGroupChipItem.vue";
import SvgIconsLocation24 from "@/components/Svg/Icons/SvgIconsLocation24.vue";
import BaseGroupIconLabel from "@/components/UI/Base/BaseGroupIconLabel.vue";
import SvgIconsCalendar from "@/components/Svg/Icons/SvgIconsCalendar.vue";
import SvgIconsViewsAmount from "@/components/Svg/Icons/SvgIconsViewsAmount.vue";
import { formatDateForAd } from "@/helpers/formatDateForAd";
import BaseTag from "@/components/UI/Base/BaseTag.vue";

export default {
  components: {
    BaseH2,
    BaseText,
    BlockPhotoMain,
    BaseH3,
    BaseGroupChipItem,
    SvgIconsLocation24,
    BaseGroupIconLabel,
    SvgIconsCalendar,
    SvgIconsViewsAmount,
    BaseTag,
  },

  props: {
    item: Object,
    isGeo: {
      type: Boolean,
      default: true,
    },
    isDateAdded: {
      type: Boolean,
      default: true,
    },
    isViewsAmount: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    // Mobile only
    const driverLicense = computed(() => {
      return props.item.requirements.perks.find(
        (perk) => perk.slug === "driver_license"
      );
    });

    // TODO: make a composable
    const TITLE_MAX_LENGTH = 60;
    const readyTitle = computed(() => {
      let title = props.item.details.title.replace(/(\r\n|\n|\r)/gm, " ");
      if (title.length > TITLE_MAX_LENGTH) {
        return title.slice(0, TITLE_MAX_LENGTH) + "...";
      } else return title;
    });

    const DESC_MAX_LENGTH = 110;
    const readyDescription = computed(() => {
      let description = props.item.details.description.replace(
        /(\r\n|\n|\r)/gm,
        " "
      );
      if (description.length > DESC_MAX_LENGTH) {
        return description.slice(0, DESC_MAX_LENGTH) + "...";
      } else return description;
    });

    const readyEmploymentDetails = computed(() => {
      let details = "";

      const offer = props.item;

      const scheduleStart = offer.schedule.dateStart.label;
      const schedulePeriodTerm = offer.schedule.period.term;
      let schedulePeriodText = "";
      if (schedulePeriodTerm.slug === "custom") {
        schedulePeriodText = `${offer.schedule.period.amount} ${offer.schedule.period.measure.label}`;
      } else if (schedulePeriodTerm.slug === "long_term") {
        schedulePeriodText = `${schedulePeriodTerm.label} okres`;
      }

      const scheduleText = `Start ${scheduleStart.toLowerCase()} na ${schedulePeriodText.toLowerCase()}.`;

      const employmentType = offer.employment.type;
      const employmentRegularity = offer.employment.regularity;
      switch (employmentType.slug) {
        case "baby_sitting":
          details = scheduleText;
          break;
        case "full_time":
          details = scheduleText;
          break;
        case "part_time":
          details = `${employmentRegularity.label}. ${scheduleText}`;
          break;

        default:
          break;
      }

      return details;
    });

    const readyFeeHour = computed(() => {
      return `do <b>${props.item.details.rate.hour}</b> zł/g.`;
    });

    const dateAddedString = computed(() => {
      if (!props.isDateAdded) {
        return;
      }
      const refreshedAt = new Date(
        props.item.meta.refreshedAt || props.item.meta.createdAt
      );
      return formatDateForAd(refreshedAt);
    });

    const viewsAmountString = computed(() => {
      if (!props.isViewsAmount) {
        return "";
      }
      return props.item.meta.visitsAll + "";
    });

    const indicatorsAmount = computed(() => {
      let amount = 0;
      if (props.isDateAdded) {
        ++amount;
      }
      if (props.isGeo) {
        ++amount;
      }
      if (props.isViewsAmount) {
        ++amount;
      }
      return amount;
    });

    const isArchived = computed(() => {
      return props.item.meta.status === "archived";
    });

    return {
      driverLicense,
      readyTitle,
      readyDescription,
      readyEmploymentDetails,
      readyFeeHour,
      dateAddedString,
      viewsAmountString,
      indicatorsAmount,
      isArchived,
    };
  },
};
</script>

<style scoped>
.nanny-list-item {
  width: var(--layout-default--width);
  position: relative;
  padding: 20px 22px;
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 20px;

  background-color: #fff;
  border-radius: 20px;

  transition: transform 0.4s;
}
.nanny-list-item--archived {
  pointer-events: none;
}
.nanny-list-item__tag {
  position: absolute;
  top: calc(var(--tag-height) / -2);
}
/* .nanny-list-item:focus {
  animation: list-item 0.6s;
}
@keyframes list-item {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
} */

.nanny-list-item__container-photo-empl {
  width: 100%;

  display: flex;
  flex-flow: row;
  column-gap: 22px;
}
.container-photo-empl__photo {
  min-width: 145px;
  height: 145px;
}
.container-photo-empl__employment-block {
  display: flex;
  flex-flow: column;
  justify-content: center;
  row-gap: 10px;
}
.employment-block__type {
  margin-top: 4px;
  line-height: 18px;
}
.employment-block .employment-block__details {
  font-size: 14px;
}
.nanny-list-item__kids-block {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 10px;
}

.kids-block .kids-block__kids {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  row-gap: 10px;
  column-gap: 20px;
}

.kids-block__special-care span {
  color: #fe881c;
  font-weight: 600;
}

.nanny-list-item__perks-container {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 10px;
}

.perks-container__fee-block {
  min-width: fit-content;
}
.nanny-list-item__perks-container:deep(.perks-container__advantages-required-block) {
  gap: 10px;
}
.perks-container__languages-block {
  min-width: fit-content;
  display: flex;
  flex-flow: row;
  column-gap: 10px;
  justify-content: flex-end;
}

.languages-block__icon {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 20px;
}
.nanny-list-item--archived .nanny-list-item__title,
.nanny-list-item--archived .nanny-list-item__container-photo-empl,
.nanny-list-item--archived .nanny-list-item__kids-block,
.nanny-list-item--archived .nanny-list-item__perks-container {
  opacity: 0.4;
}
.nanny-list-item__indicators {
  padding: 0 7px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 0;
  bottom: -25px;
}
.nanny-list-item__indicators[indicators-amount="1"] {
  justify-content: center;
}
.indicators__geo,
.indicators__date-added,
.indicators__views-amount {
  display: flex;
  flex-flow: row;
  align-items: center;
  column-gap: 4px;
  opacity: 0.5;
}
.geo__label,
.date-added__label,
.views-amount__label {
  font: var(--system-font);
  font-size: 12px;
}
.geo__icon,
.date-added__icon {
  width: 12px;
  height: 12px;
}
.views-amount__icon {
  margin-top: 2px;
}
.nanny-list-item__delete-button {
  position: absolute;
  top: 3px;
  right: 3px;
  z-index: 20;
  opacity: 0.3;
  transition: opacity 0.2s;
}
</style>
