const { calcDaysFromNow } = require("./calcDaysFromNow");

export const formatDateForAd = (refreshedDate) => {
  const refreshedDaysAgo = calcDaysFromNow(refreshedDate);
  if (refreshedDaysAgo < 1) {
    return "Dodano dzisiaj";
  }
  if (refreshedDaysAgo < 2) {
    return "Dodano wczoraj";
  }
  if (refreshedDaysAgo < 32) {
    return `Dodano ${refreshedDaysAgo} dni temu`;
  }
  const yy = refreshedDate.getFullYear().toString().substr(-2);
  const mm = (refreshedDate.getMonth() + 1).toString().padStart(2, "0");
  const dd = refreshedDate.getDate().toString().padStart(2, "0");
  return `Dodano ${dd}.${mm}.${yy}`;
};
