<template>
  <div
    :class="['nanny-list-item', { 'nanny-list-item--archived': isArchived }]"
  >
    <BaseTag
      v-if="isArchived"
      :text="'Ogłoszenie archiwalne'"
      class="nanny-list-item__tag"
    />

    <div class="top-container nanny-list-item__top-container">
      <div class="left-container top-container__left-container">
        <BlockPhotoMain
          :src="item.details.photoMain?.src"
          :default-color-num="item.details.photoMainColorNum"
          :is-list-view="true"
          class="photo left-container__photo"
        />
      </div>
      <div class="middle-container top-container__middle-container">
        <div class="title-block middle-container__title-block">
          <BaseH2 :text="readyTitle" />
        </div>
        <div class="description middle-container__description">
          <BaseText :html="readyDescription" :is-small="true" />
        </div>
      </div>
      <div class="right-container top-container__right-container">
        <div class="employment-block right-container__employment-block">
          <BaseH3 :text="item.employment.type.label" />
          <BaseText :text="readyEmploymentDetails" :is-small="true" />
        </div>
        <div class="kids-block right-container__kids-block">
          <div class="kids-amount kids-block__kids-amount">
            <span>Dla</span>
            <BaseGroupIconLabel
              :items="item.details.kids"
              class="kids kids-amount__kids"
            />
          </div>

          <base-text
            v-if="item.details.isSpecialCare"
            :is-small="true"
            class="years kids-block__special-care"
          >
            <span>!</span> {{ item.details.specialCare.labelShort }}</base-text
          >
        </div>
      </div>
    </div>

    <div class="bottom-container nanny-list-item__bottom-container">
      <div class="fee-block bottom-container__fee-block">
        <BaseGroupChipItem
          :label="readyFeeHour"
          :style="'background-color: #BFB3F9;'"
        />
      </div>
      <BaseGroupChip
        :items="item.requirements.perks"
        class="advantages-block bottom-container__advantages-block"
      />
      <div class="languages-block bottom-container__languages-block">
        <img
          v-for="language in item.requirements.languages"
          :key="language.label"
          :src="language.iconSrc"
          class="icon languages-block__icon"
        />
      </div>
    </div>

    <div v-if="isGeo" class="geo nanny-list-item__geo">
      <SvgIconsLocation24 class="icon geo__icon" />
      <label class="label geo__label">
        {{ item.details.location.label }} od Ciebie
      </label>
    </div>

    <div
      v-if="isDateAdded || isViewsAmount"
      class="nanny-list-item__right-indicators"
    >
      <div v-if="isDateAdded" class="date-added nanny-list-item__date-added">
        <SvgIconsCalendar class="icon date-added__icon" />
        <label class="label date-added__label">
          {{ dateAddedString }}
        </label>
      </div>

      <div
        v-if="isViewsAmount"
        class="views-amount nanny-list-item__views-amount"
      >
        <SvgIconsViewsAmount class="icon views-amount__icon" />
        <label class="label views-amount__label">
          {{ viewsAmountString }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/reactivity";
import { formatDateForAd } from "@/helpers/formatDateForAd";

import BaseH2 from "@/components/UI/Base/BaseH2.vue";
import BaseText from "@/components/UI/Base/BaseText.vue";
import BlockPhotoMain from "@/components/BlockPhotoMain.vue";
import BaseH3 from "@/components/UI/Base/BaseH3.vue";
import BaseGroupChipItem from "@/components/UI/Base/BaseGroupChipItem.vue";
import BaseGroupChip from "@/components/UI/Base/BaseGroupChip.vue";
import SvgIconsLocation24 from "@/components/Svg/Icons/SvgIconsLocation24.vue";
import BaseGroupIconLabel from "@/components/UI/Base/BaseGroupIconLabel.vue";
import SvgIconsCalendar from "@/components/Svg/Icons/SvgIconsCalendar.vue";
import SvgIconsViewsAmount from "@/components/Svg/Icons/SvgIconsViewsAmount.vue";
import BaseTag from "@/components/UI/Base/BaseTag.vue";

export default {
  components: {
    BaseH2,
    BaseText,
    BlockPhotoMain,
    BaseH3,
    BaseGroupChipItem,
    BaseGroupChip,
    SvgIconsLocation24,
    BaseGroupIconLabel,
    SvgIconsCalendar,
    SvgIconsViewsAmount,
    BaseTag,
  },

  props: {
    item: Object,
    isGeo: {
      type: Boolean,
      default: true,
    },
    isDateAdded: {
      type: Boolean,
      default: true,
    },
    isViewsAmount: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const TITLE_MAX_LENGTH = 60;
    const readyTitle = computed(() => {
      let title = props.item.details.title.replace(/(\r\n|\n|\r)/gm, " ");
      if (title.length > TITLE_MAX_LENGTH) {
        return title.slice(0, TITLE_MAX_LENGTH) + "...";
      } else return title;
    });

    const DESC_MAX_LENGTH = 110;
    const readyDescription = computed(() => {
      let description = props.item.details.description.replace(
        /(\r\n|\n|\r)/gm,
        " "
      );
      if (description.length > DESC_MAX_LENGTH) {
        return description.slice(0, DESC_MAX_LENGTH) + "...";
      } else return description;
    });

    const readyEmploymentDetails = computed(() => {
      let details = "";

      const offer = props.item;

      const scheduleStart = offer.schedule.dateStart.label;
      const schedulePeriodTerm = offer.schedule.period.term;
      let schedulePeriodText = "";
      if (schedulePeriodTerm.slug === "custom") {
        schedulePeriodText = `${offer.schedule.period.amount} ${offer.schedule.period.measure.label}`;
      } else if (schedulePeriodTerm.slug === "long_term") {
        schedulePeriodText = `${schedulePeriodTerm.label} okres`;
      }

      const scheduleText = `Start ${scheduleStart.toLowerCase()} na ${schedulePeriodText.toLowerCase()}.`;

      const employmentType = offer.employment.type;
      const employmentRegularity = offer.employment.regularity;
      switch (employmentType.slug) {
        case "baby_sitting":
          details = scheduleText;
          break;
        case "full_time":
          details = scheduleText;
          break;
        case "part_time":
          details = `${employmentRegularity.label}. ${scheduleText}`;
          break;

        default:
          break;
      }

      return details;
    });

    const readyFeeHour = computed(() => {
      return `do <b>${props.item.details.rate.hour}</b> zł/g.`;
    });

    const dateAddedString = computed(() => {
      if (!props.isDateAdded) {
        return;
      }
      const refreshedAt = new Date(
        props.item.meta.refreshedAt || props.item.meta.createdAt
      );
      return formatDateForAd(refreshedAt);
    });

    const viewsAmountString = computed(() => {
      if (!props.isViewsAmount) {
        return "";
      }
      return props.item.meta.visitsAll + "";
    });

    // const readyPerks = computed(() => {
    //   let perks = [];
    //   if (props.item.perks.length) {
    //     props.item.perks.forEach((perk) => {
    //       perks.push({
    //         slug: perk.slug,
    //         label: perk.label,
    //       });
    //     });
    //   }
    //   return perks;
    // });

    const isArchived = computed(() => {
      return props.item.meta.status === "archived";
    });

    return {
      readyTitle,
      readyDescription,
      readyEmploymentDetails,
      readyFeeHour,
      // readyPerks,
      dateAddedString,
      viewsAmountString,
      isArchived,
    };
  },
};
</script>

<style scoped>
.nanny-list-item {
  width: var(--layout-default--width);
  position: relative;
  padding: 30px;
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 20px;

  background-color: #fff;
  border-radius: 20px;

  transition: transform 0.4s;
}
.nanny-list-item--archived {
  pointer-events: none;
}
.nanny-list-item__tag {
  position: absolute;
  top: calc(var(--tag-height) / -2);
}
.nanny-list-item:hover .nanny-list-item__tag {
  top: calc((var(--tag-height) / -2) - 2px);
}
.nanny-list-item:hover {
  border: 2px solid rgba(103, 175, 241, 1);
  padding: 28px;
  cursor: pointer;

  will-change: transform;
}
/* .nanny-list-item:focus {
  animation: list-item 0.6s;
}
@keyframes list-item {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
} */
.nanny-list-item__top-container {
  width: 100%;
  display: flex;
  flex-flow: row;
  column-gap: 30px;
}
.top-container__left-container {
  min-width: fit-content;
}
.top-container__middle-container {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  row-gap: 4px;
}
.top-container__right-container {
  min-width: 320px;
  margin-top: 4px;

  display: flex;
  flex-flow: column;
  row-gap: 20px;

  position: relative;
}
.right-container__kids-block {
  display: flex;
  flex-flow: column;
  row-gap: 6px;
}
.kids-block__special-care span {
  color: #fe881c;
  font-weight: 600;
}
.kids-block__kids-amount {
  display: flex;
  flex-flow: row;
  align-items: center;
  column-gap: 13px;
}
.kids-amount:deep(.kids-amount__kids) {
  justify-content: flex-start;
  row-gap: 7px;
  column-gap: 13px;
}

.nanny-list-item__bottom-container {
  width: 100%;
  height: 40px;
  display: flex;
  flex-flow: row;
  column-gap: 10px;

  overflow: hidden;
}
.nanny-list-item--archived .nanny-list-item__top-container,
.nanny-list-item--archived .nanny-list-item__bottom-container {
  opacity: 0.4;
}
.bottom-container__fee-block {
  min-width: fit-content;
}
.nanny-list-item__bottom-container:deep(.bottom-container__advantages-block) {
  gap: 10px;
}
.bottom-container__languages-block {
  min-width: fit-content;
  display: flex;
  flex-flow: row;
  column-gap: 10px;
  justify-content: flex-end;
}

.languages-block__icon {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 20px;
}
.nanny-list-item__geo,
.nanny-list-item__date-added,
.nanny-list-item__views-amount,
.nanny-list-item__right-indicators {
  display: flex;
  flex-flow: row;
  align-items: center;
  column-gap: 2px;
}
.nanny-list-item__geo,
.nanny-list-item__right-indicators {
  position: absolute;
  bottom: -24px;
  opacity: 0.5;
}
.nanny-list-item__geo {
  left: 15px;
}
.nanny-list-item__right-indicators {
  right: 15px;
}
.nanny-list-item:hover .nanny-list-item__geo,
.nanny-list-item:hover .nanny-list-item__right-indicators {
  bottom: -26px;
}
.nanny-list-item:hover .nanny-list-item__geo {
  left: 13px;
}
.nanny-list-item:hover .nanny-list-item__right-indicators {
  right: 13px;
}
.nanny-list-item__right-indicators {
  display: flex;
  column-gap: 22px;
}
.geo__label,
.date-added__label,
.views-amount__label {
  font: var(--system-font);
  font-size: 12px;
}
.geo__icon,
.date-added__icon {
  width: 12px;
  height: 12px;
}
.views-amount__icon {
  margin-top: 2px;
}
</style>
