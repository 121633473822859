<template>
  <component
    :is="OfferNannyListItemComponent"
    :item="item"
    :is-geo="isGeo"
    :is-date-added="isDateAdded"
    :is-views-amount="isViewsAmount"
    @click="routeToDetails"
  />
</template>

<script>
import { computed } from "@vue/reactivity";

import useIsMobile from "@/composables/useIsMobile";

import OfferNannyListItemDesktop from "@/components/Offer/Nanny/OfferNannyListItemDesktop.vue";
import OfferNannyListItemMobile from "@/components/Offer/Nanny/OfferNannyListItemMobile.vue";
import { ROUTE_OFFER_NANNY_DETAILS_NAME } from "@/router/constants";
import { useRouter } from "vue-router";

export default {
  props: {
    item: Object,
    isGeo: {
      type: Boolean,
      default: true,
    },
    isDateAdded: {
      type: Boolean,
      default: true,
    },
    isViewsAmount: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { isMobile } = useIsMobile();

    const OfferNannyListItemComponent = computed(() => {
      return isMobile.value
        ? OfferNannyListItemMobile
        : OfferNannyListItemDesktop;
    });

    const router = useRouter();
    const routeToDetails = () => {
      router.push({
        name: ROUTE_OFFER_NANNY_DETAILS_NAME,
        params: { id: props.item.id },
      });
    };

    return { OfferNannyListItemComponent, routeToDetails };
  },
};
</script>
